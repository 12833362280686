import React from "react";
import { withTrans } from "../../i18n/withTrans";
import "../../style/components/associates.scss";
import classNames from "classnames";

// components
import Card from "../_partials/_card";
import TitleSeparator from "../titleSeparator";

class Associates extends React.Component {
  render() {
    const { info } = this.props;
    return (
      <div
        className={classNames("associates", "flex-column", "align-items-center", {
          "topDiagonal pt-100": info.topDiagonal,
          backgroundGrey: info.backgroundGrey,
        })}
      >
        <div className="title pt-30 flex-column pl-30 pr-30 text-center">
          <TitleSeparator info={info}></TitleSeparator>
        </div>
        <div className="row ml-10 mr-10 mt-0 mb-100 d-flex">
          {info.associates.map((associate, i) => (
            <Card
              logoImg={associate?.logoImg}
              logoDescription = {associate.logoDescription}
              description={associate.description}
              photo={associate?.photo}
              testimonyName={associate.testimonyName}
              title={associate.title}
              key={i}
            ></Card>
          ))}
        </div>
      </div>
    );
  }
}

export default withTrans(Associates);
