//images associates
import angelika from "../../images/activewearAssociates/angelika-schindler-obenhaus.jpg";
import fabian from "../../images/activewearAssociates/engelhorn-avatar-150x150.png";
import florian from "../../images/activewearAssociates/florian-ehmes-266x266.png";
import hpAvatar from "../../images/activewearAssociates/h-p-avatar-2-150x150.png";
import oliver from "../../images/activewearAssociates/s-oliver-avatar-150x150.png";
import schoffel from "../../images/activewearAssociates/scho-ffel-avatar-150x150.png";

export const associates = (t) => {
    return {
        cardsInfo: {
            associates: [
              {
                description:t("associatesSecondary.i12Description"),
                photo: florian,
                testimonyName: t("associatesSecondary.i12Testimony"),
                title: t("associatesSecondary.i12Title"),
              },
      
              {
                description: t("associatesSecondary.schoffelDescription"),
                photo: schoffel,
                testimonyName: t("associatesSecondary.schoffelTestimony"),
                title: t("associatesSecondary.schoffelTitle"),
              },
      
              {
                description: t("associatesSecondary.katagDescription"),
                photo: angelika,
                testimonyName: t("associatesSecondary.katagTestimony"),
                title: t("associatesSecondary.katagTitle"),
              },
              {
                description: t("associatesSecondary.hpDescription"),
                photo: hpAvatar,
                testimonyName: t("associatesSecondary.hpTestimony"),
                title: t("associatesSecondary.hpTitle"),
              },
              {
                description: t("associatesSecondary.engelhornDescription"),
                photo: fabian,
                testimonyName: t("associatesSecondary.engelhornTestimony"),
                title: t("associatesSecondary.engelhornTitle"),
              },
              {
                description: t("associatesSecondary.mamutDescription"),
                photo: oliver,
                testimonyName: t("associatesSecondary.mamutTestimony"),
                title: t("associatesSecondary.mamutTitle"),
              },
            ],
            textCenter: true,
            topDiagonal: false,
            backgroundGrey: false,
          }
    }
};