import * as React from "react";
import { withTrans } from "../../i18n/withTrans";

// components
import TextImgBackgroundFull from "../textImgBackgroundFull";
import BulletsSection from "../bulletsSection";
import TitleSeparator from "../titleSeparator";
import Button from "../_partials/_button";
import Associates from "./associates";

class CenteredPage extends React.Component {
  render() {
      const { content, associates } = this.props;
    return (
      <>
        <div>
          <TextImgBackgroundFull info={content.topInfo}></TextImgBackgroundFull>
        </div>

        <div className="pushTopDiagonal paddingSidesBig z-index-positive position-relative mb-20">
          <div className ="backgroundWhite boxRadius boxShadow text-center pb-30">
           <img className="w-100" src={content.image1.src} alt={content.image1.alt} />
           {!content.textBased && <Button cta={content.cta} ></Button> }
          </div>
        </div>

        {!content.textBased ? (
          <div className = "pushTopDiagonal position-relative  backgroundGrey paddingSidesBig d-flex justify-content-center topBottomDiagonal pt-100 pb-100">
            <BulletsSection info={content.bullet1} centeredPage={true}></BulletsSection>
          </div>
        ) : 
        <div className = "pushTopDiagonal position-relative  backgroundGrey text-center paddingSidesBig topBottomDiagonal pt-100 pb-150">
            <TitleSeparator info={content.text1}></TitleSeparator>
            <Button cta={content.cta} ></Button>
        </div>
        }
      
        <div className="pushTopDiagonal paddingSidesBig z-index-positive position-relative">
          <div className ="backgroundWhite boxRadius boxShadow ">
            <img className="w-100" src={content.image2.src} alt={content.image2.alt} />
          </div>
        </div>

        {!content.textBased ? (
          <div className = "pushTopDiagonal position-relative  backgroundGrey paddingSidesBig d-flex justify-content-center topBottomDiagonal pt-100 pb-100">
            <BulletsSection info={content.bullet2} centeredPage={true}></BulletsSection>
          </div>
        ) : 
        <div className = "pushTopDiagonal position-relative  backgroundGrey text-center paddingSidesBig topBottomDiagonal pt-100 pb-150">
            <TitleSeparator info={content.text2}></TitleSeparator>
            <Button cta={content.cta} ></Button>
        </div>
        }

        <div className="pushTopDiagonal paddingSidesBig z-index-positive position-relative">
          <div className ="backgroundWhite boxRadius boxShadow">
            <img className="w-100" src={content.image3.src} alt={content.image3.alt} />
          </div>
        </div>

        {!content.textBased && 
          <div className = "pushTopDiagonal position-relative  backgroundGrey paddingSidesBig d-flex justify-content-center topBottomDiagonal pt-100 pb-100">
            <BulletsSection info={content.bullet3} centeredPage={true}></BulletsSection>
          </div>
        }
    
      { content.image4 &&
          <div className="pushTopDiagonal paddingSidesBig z-index-positive position-relative">
          <div className ="backgroundWhite boxRadius boxShadow">
            <img className="w-100" src={content.image4.src} alt={content.image4.alt} />
          </div>
        </div>
      }
        <div className="pushTopDiagonal position-relative  text-center topBottomDiagonal backgroundGrey pt-100 pb-20">
          <div className = "paddingSidesBig">
          <TitleSeparator  info={content.aboutUs}></TitleSeparator>
          </div>      
          <Associates info={associates.cardsInfo}></Associates>
        </div>

        <div className="text-center pb-20">
          <TitleSeparator info={content.partnersTitle}></TitleSeparator>
          <img className="w-100 paddingSidesBig pt-50 pb-50" src={content.partners.src} alt={content.partners.alt} />
        </div>
      </>
    );
  }
}

export default withTrans(CenteredPage);
