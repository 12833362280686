import React from "react";

import "../../style/components/card.scss";
import classNames from "classnames";

class Card extends React.Component {
  render() {
    const {
      logoImg,
      logoDescription,
      photo,
      description,
      testimonyName,
      title,
      caseStudy,
      backgroundColor,
      info,
      extraClass,
    } = this.props;
    return (
      <>
        <div
          className={classNames(
            logoImg || info
              ? "card p-20 d-flex mt-40 ml-10 mr-10 mb-0"
              : "no-height card p-20 d-flex mt-40 ml-10 mr-10 mb-0",
            backgroundColor ? backgroundColor : "",
            extraClass ? extraClass : ""
          )}
        >
          {logoImg && (
            <div
              className={
                (caseStudy ? "logoContainer" : "logoImg") +
                " d-flex justify-content-center"
              }
            >
              <img
                src={logoImg}
                alt={logoDescription}
                className={caseStudy ? " " : "logoImg"}
              ></img>
            </div>
          )}
          {description && (
            <div className="font14 mb-10 lineHeight1_3 font-italic colorDarkLight">
              {description}
            </div>
          )}
          <div className="info mt-10 d-flex">
            {photo && (
              <div>
                <img className="photo" src={photo} alt={testimonyName}></img>
              </div>
            )}
            {testimonyName ||
              (title && (
                <div className="personalInfo">
                  <div
                    className={
                      "name font14 " +
                      (caseStudy ? "text-center pb-25 border-bottom" : "")
                    }
                  >
                    {testimonyName}
                  </div>
                  <div
                    className={
                      "title font18 " +
                      (caseStudy
                        ? "text-center pt-25 case-study-description"
                        : "")
                    }
                  >
                    {title}
                  </div>
                </div>
              ))}
            {info && (
              <div>
                <h3 className="font32 text-center font-weight-bold">
                  {info.title}
                </h3>
                <p className="font20 text-center font-weight-bold pt-50">
                  {info.text}
                </p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Card;
